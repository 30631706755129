import React from 'react';

function Contact() {
    return (
        <div className="Home">
            Contact

            Address
            Phone Numbers
        </div>
    );
}

export default Contact;